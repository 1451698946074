import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { MessageService } from './shared/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../shared/services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private messageService: MessageService, private _toastrService: ToastrService, private loadingService: LoaderService){

  }
  // ngOnInit(): void {
  // }


  // @HostListener('window:unload', [ '$event' ])
  // unloadHandler(event) {
  //   
  //   alert("sadsadadsd");
  //   localStorage.clear();
  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
    
  // }

  ngOnInit(): void {
    this.requestPermission();
    this.listen();
  }
  
  requestPermission() {
    this.loadingService.show();
    const messaging = getMessaging();
    getToken(messaging, 
     { vapidKey: environment.firebaseConfig.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           console.log("Hurraaa!!! we got the token.....");
           console.log(currentToken);
           localStorage.setItem("vistimAPanelFCMToken", currentToken);
           this.loadingService.hide();
         } else {
           console.log('No registration token available. Request permission to generate one.');
           this.loadingService.hide();
           //location.reload();
         }
     }).catch((err) => {
       console.log('An error occurred while retrieving token. ', err);
       this.loadingService.hide();
       //location.reload();
    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      
      // console.log('Message received. ', payload);
      this._toastrService.success(payload?.notification?.body);
      console.log('Message received. ', payload);

      this.messageService.messageEvent.next(payload);
      // this.message=payload;
    });
  }
}
