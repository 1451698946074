import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingInterceptor } from 'src/shared/loading.interceptor';
import { SharedModule } from 'src/shared/shared.module';
import { environment } from 'src/environments/environment';
import { initializeApp } from "firebase/app";


import { CalendarModule, DateAdapter, MOMENT } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { DragAndDropModule } from 'angular-draggable-droppable';
import * as moment from 'moment';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { JwtInterceptor } from './shared/jwt.interceptor';

const accountModule = () => import('./../account/account.module').then(x => x.AccountModule);
const mainLayoutModule = () => import('../app/_modules/main-layout.module').then(x => x.MainLayoutModule);
initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    DragAndDropModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot([
      { path: "account", loadChildren: accountModule },
      { path: 'admin', loadChildren: mainLayoutModule },
      // { path: 'home', component: HomeComponent },
       { path: "**", redirectTo: "admin" },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
    ]),
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
  },
    {
      provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
