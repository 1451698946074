import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../services/loader.service";

@Component({
  selector: "app-full-screen-loader",
  templateUrl: "./full-screen-loader.component.html",
  styleUrls: ["./full-screen-loader.component.css"],
})
export class FullScreenLoaderComponent implements OnInit {
  loader: boolean = true;
  constructor(public loaderService:LoaderService) {}

  // options: AnimationOptions = {
  //   animationData: {
  //     v: "4.8.0",
  //     meta: { g: "LottieFiles AE 1.0.0", a: "", k: "", d: "", tc: "" },
  //     fr: 29.9700012207031,
  //     ip: 0,
  //     op: 180.00000733155,
  //     w: 1000,
  //     h: 1000,
  //     nm: "Group 1207",
  //     ddd: 0,
  //     assets: [],
  //     layers: [
  //       {
  //         ddd: 0,
  //         ind: 1,
  //         ty: 3,
  //         nm: "Null 1",
  //         sr: 1,
  //         ks: {
  //           o: { a: 0, k: 0, ix: 11 },
  //           r: { a: 0, k: 0, ix: 10 },
  //           p: { a: 0, k: [500, 500, 0], ix: 2 },
  //           a: { a: 0, k: [0, 0, 0], ix: 1 },
  //           s: { a: 0, k: [99, 99, 100], ix: 6 },
  //         },
  //         ao: 0,
  //         ip: 0,
  //         op: 180.00000733155,
  //         st: 0,
  //         bm: 0,
  //       },
  //       {
  //         ddd: 0,
  //         ind: 3,
  //         ty: 4,
  //         nm: "Layer 2 Outlines",
  //         parent: 1,
  //         sr: 1,
  //         ks: {
  //           o: { a: 0, k: 100, ix: 11 },
  //           r: {
  //             a: 1,
  //             k: [
  //               {
  //                 i: { x: [0.833], y: [0.833] },
  //                 o: { x: [0.167], y: [0.167] },
  //                 t: 0,
  //                 s: [0],
  //               },
  //               { t: 180.00000733155, s: [360] },
  //             ],
  //             ix: 10,
  //           },
  //           p: { a: 0, k: [0, 0, 0], ix: 2 },
  //           a: { a: 0, k: [500, 500.5, 0], ix: 1 },
  //           s: { a: 0, k: [100, 100, 100], ix: 6 },
  //         },
  //         ao: 0,
  //         shapes: [
  //           {
  //             ty: "gr",
  //             it: [
  //               {
  //                 ind: 0,
  //                 ty: "sh",
  //                 ix: 1,
  //                 ks: {
  //                   a: 0,
  //                   k: {
  //                     i: [
  //                       [22.916, 0],
  //                       [0, -148.95],
  //                       [-148.951, 0],
  //                       [0, -22.916],
  //                       [22.916, 0],
  //                       [0, 194.784],
  //                       [-194.783, 0],
  //                       [0, -22.916],
  //                     ],
  //                     o: [
  //                       [-148.951, 0],
  //                       [0, 148.951],
  //                       [22.916, 0],
  //                       [0, 22.916],
  //                       [-194.783, 0],
  //                       [0, -194.783],
  //                       [22.916, 0],
  //                       [0, 22.916],
  //                     ],
  //                     v: [
  //                       [155.602, -269.709],
  //                       [-114.108, -0.001],
  //                       [155.602, 269.708],
  //                       [197.094, 311.202],
  //                       [155.602, 352.697],
  //                       [-197.095, -0.001],
  //                       [155.602, -352.697],
  //                       [197.094, -311.202],
  //                     ],
  //                     c: true,
  //                   },
  //                   ix: 2,
  //                 },
  //                 nm: "Path 1",
  //                 mn: "ADBE Vector Shape - Group",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "fl",
  //                 c: { a: 0, k: [1, 0.344999994016, 0.216000007181, 1], ix: 4 },
  //                 o: { a: 0, k: 100, ix: 5 },
  //                 r: 1,
  //                 bm: 0,
  //                 nm: "Fill 1",
  //                 mn: "ADBE Vector Graphic - Fill",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "tr",
  //                 p: { a: 0, k: [344.407, 499.98], ix: 2 },
  //                 a: { a: 0, k: [0, 0], ix: 1 },
  //                 s: { a: 0, k: [100, 100], ix: 3 },
  //                 r: { a: 0, k: 0, ix: 6 },
  //                 o: { a: 0, k: 100, ix: 7 },
  //                 sk: { a: 0, k: 0, ix: 4 },
  //                 sa: { a: 0, k: 0, ix: 5 },
  //                 nm: "Transform",
  //               },
  //             ],
  //             nm: "Group 1",
  //             np: 2,
  //             cix: 2,
  //             bm: 0,
  //             ix: 1,
  //             mn: "ADBE Vector Group",
  //             hd: false,
  //           },
  //         ],
  //         ip: 0,
  //         op: 180.00000733155,
  //         st: 0,
  //         bm: 0,
  //       },
  //       {
  //         ddd: 0,
  //         ind: 5,
  //         ty: 4,
  //         nm: "Layer 1 Outlines",
  //         parent: 1,
  //         sr: 1,
  //         ks: {
  //           o: { a: 0, k: 100, ix: 11 },
  //           r: { a: 0, k: 0, ix: 10 },
  //           p: { a: 0, k: [0, 0, 0], ix: 2 },
  //           a: { a: 0, k: [500, 500.5, 0], ix: 1 },
  //           s: { a: 0, k: [100, 100, 100], ix: 6 },
  //         },
  //         ao: 0,
  //         shapes: [
  //           {
  //             ty: "gr",
  //             it: [
  //               {
  //                 ind: 0,
  //                 ty: "sh",
  //                 ix: 1,
  //                 ks: {
  //                   a: 0,
  //                   k: {
  //                     i: [
  //                       [275.684, 0],
  //                       [83.402, -62.365],
  //                       [0, 0],
  //                       [-117.053, 0],
  //                       [0, -229.958],
  //                       [121.694, -72.91],
  //                       [8.154, -31.951],
  //                       [0, 204.043],
  //                     ],
  //                     o: [
  //                       [-111.908, 0],
  //                       [0, 0],
  //                       [75.767, -77.925],
  //                       [229.958, 0],
  //                       [0, 151.904],
  //                       [-0.035, 34.064],
  //                       [175.639, -77.643],
  //                       [0, -275.684],
  //                     ],
  //                     v: [
  //                       [-100.621, -478.794],
  //                       [-399.375, -379.458],
  //                       [-399.378, -269.415],
  //                       [-100.621, -395.807],
  //                       [316.391, 21.204],
  //                       [113.074, 379.201],
  //                       [100.858, 478.794],
  //                       [399.378, 21.204],
  //                     ],
  //                     c: true,
  //                   },
  //                   ix: 2,
  //                 },
  //                 nm: "Path 1",
  //                 mn: "ADBE Vector Shape - Group",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "fl",
  //                 c: { a: 0, k: [1, 0.344999994016, 0.216000007181, 1], ix: 4 },
  //                 o: { a: 0, k: 100, ix: 5 },
  //                 r: 1,
  //                 bm: 0,
  //                 nm: "Fill 1",
  //                 mn: "ADBE Vector Graphic - Fill",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "tr",
  //                 p: { a: 0, k: [600.622, 478.794], ix: 2 },
  //                 a: { a: 0, k: [0, 0], ix: 1 },
  //                 s: { a: 0, k: [100, 100], ix: 3 },
  //                 r: { a: 0, k: 0, ix: 6 },
  //                 o: { a: 0, k: 100, ix: 7 },
  //                 sk: { a: 0, k: 0, ix: 4 },
  //                 sa: { a: 0, k: 0, ix: 5 },
  //                 nm: "Transform",
  //               },
  //             ],
  //             nm: "Group 1",
  //             np: 2,
  //             cix: 2,
  //             bm: 0,
  //             ix: 1,
  //             mn: "ADBE Vector Group",
  //             hd: false,
  //           },
  //           {
  //             ty: "gr",
  //             it: [
  //               {
  //                 ind: 0,
  //                 ty: "sh",
  //                 ix: 1,
  //                 ks: {
  //                   a: 0,
  //                   k: {
  //                     i: [
  //                       [0, 118.02],
  //                       [-118.019, 0],
  //                       [0, -118.019],
  //                       [118.019, 0],
  //                     ],
  //                     o: [
  //                       [0, -118.019],
  //                       [118.019, 0],
  //                       [0, 118.02],
  //                       [-118.019, 0],
  //                     ],
  //                     v: [
  //                       [-213.692, -0.001],
  //                       [0.001, -213.693],
  //                       [213.693, -0.001],
  //                       [0.001, 213.693],
  //                     ],
  //                     c: true,
  //                   },
  //                   ix: 2,
  //                 },
  //                 nm: "Path 1",
  //                 mn: "ADBE Vector Shape - Group",
  //                 hd: false,
  //               },
  //               {
  //                 ind: 1,
  //                 ty: "sh",
  //                 ix: 2,
  //                 ks: {
  //                   a: 0,
  //                   k: {
  //                     i: [
  //                       [72.186, 0],
  //                       [0, -72.186],
  //                       [-72.187, 0],
  //                       [0, 72.187],
  //                     ],
  //                     o: [
  //                       [-72.187, 0],
  //                       [0, 72.187],
  //                       [72.186, 0],
  //                       [0, -72.186],
  //                     ],
  //                     v: [
  //                       [0.001, -130.706],
  //                       [-130.704, -0.001],
  //                       [0.001, 130.705],
  //                       [130.706, -0.001],
  //                     ],
  //                     c: true,
  //                   },
  //                   ix: 2,
  //                 },
  //                 nm: "Path 2",
  //                 mn: "ADBE Vector Shape - Group",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "mm",
  //                 mm: 1,
  //                 nm: "Merge Paths 1",
  //                 mn: "ADBE Vector Filter - Merge",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "fl",
  //                 c: { a: 0, k: [1, 0.344999994016, 0.216000007181, 1], ix: 4 },
  //                 o: { a: 0, k: 100, ix: 5 },
  //                 r: 1,
  //                 bm: 0,
  //                 nm: "Fill 1",
  //                 mn: "ADBE Vector Graphic - Fill",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "tr",
  //                 p: { a: 0, k: [500.01, 499.982], ix: 2 },
  //                 a: { a: 0, k: [0, 0], ix: 1 },
  //                 s: { a: 0, k: [100, 100], ix: 3 },
  //                 r: { a: 0, k: 0, ix: 6 },
  //                 o: { a: 0, k: 100, ix: 7 },
  //                 sk: { a: 0, k: 0, ix: 4 },
  //                 sa: { a: 0, k: 0, ix: 5 },
  //                 nm: "Transform",
  //               },
  //             ],
  //             nm: "Group 2",
  //             np: 4,
  //             cix: 2,
  //             bm: 0,
  //             ix: 2,
  //             mn: "ADBE Vector Group",
  //             hd: false,
  //           },
  //           {
  //             ty: "gr",
  //             it: [
  //               {
  //                 ind: 0,
  //                 ty: "sh",
  //                 ix: 1,
  //                 ks: {
  //                   a: 0,
  //                   k: {
  //                     i: [
  //                       [-32.827, 6.806],
  //                       [-3.955, 34.046],
  //                       [44.81, 0],
  //                       [0, 229.958],
  //                       [-22.696, 51.368],
  //                       [0, 0],
  //                       [0, -122.778],
  //                       [-275.684, 0],
  //                     ],
  //                     o: [
  //                       [13.748, -27.374],
  //                       [-40.485, 13.138],
  //                       [-229.958, 0],
  //                       [0, -59.625],
  //                       [0, 0],
  //                       [-73.733, 87.095],
  //                       [0, 275.684],
  //                       [34.814, 0],
  //                     ],
  //                     v: [
  //                       [287.397, 400.84],
  //                       [314.237, 307.995],
  //                       [185.762, 328.236],
  //                       [-231.249, -88.776],
  //                       [-195.982, -256.53],
  //                       [-195.982, -411.221],
  //                       [-314.237, -88.776],
  //                       [185.762, 411.221],
  //                     ],
  //                     c: true,
  //                   },
  //                   ix: 2,
  //                 },
  //                 nm: "Path 1",
  //                 mn: "ADBE Vector Shape - Group",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "fl",
  //                 c: {
  //                   a: 0,
  //                   k: [0.289999988032, 0.289999988032, 0.289999988032, 1],
  //                   ix: 4,
  //                 },
  //                 o: { a: 0, k: 100, ix: 5 },
  //                 r: 1,
  //                 bm: 0,
  //                 nm: "Fill 1",
  //                 mn: "ADBE Vector Graphic - Fill",
  //                 hd: false,
  //               },
  //               {
  //                 ty: "tr",
  //                 p: { a: 0, k: [314.237, 588.779], ix: 2 },
  //                 a: { a: 0, k: [0, 0], ix: 1 },
  //                 s: { a: 0, k: [100, 100], ix: 3 },
  //                 r: { a: 0, k: 0, ix: 6 },
  //                 o: { a: 0, k: 100, ix: 7 },
  //                 sk: { a: 0, k: 0, ix: 4 },
  //                 sa: { a: 0, k: 0, ix: 5 },
  //                 nm: "Transform",
  //               },
  //             ],
  //             nm: "Group 3",
  //             np: 4,
  //             cix: 2,
  //             bm: 0,
  //             ix: 3,
  //             mn: "ADBE Vector Group",
  //             hd: false,
  //           },
  //         ],
  //         ip: 0,
  //         op: 180.00000733155,
  //         st: 0,
  //         bm: 0,
  //       },
  //     ],
  //     markers: [],
  //   },
  // };

  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
