import { Component, ElementRef, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-counter-component',
  templateUrl: './counter.component.html'
})
export class CounterComponent {
  public currentCount = 0;

  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  sig: SignaturePad;
  
  public incrementCounter() {
    this.currentCount++;
  }


  ngOnInit() {
    this.sig = new SignaturePad(this.canvas.nativeElement);
  }

  clear() {
    this.sig.clear();
  }
}
