import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class MessageService {

    messageEvent= new Subject<any>();
    baseUrl: string;
  
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      this.baseUrl = baseUrl;
    }
  
    //test login
     
    getClimeList(ssn:any): Observable<any> {
      return this.http.get(
        `${this.baseUrl}api/ClaimantInformation/get-claimant-information/${ssn}`,  
      );
    }
}
