
import { Injectable, TemplateRef  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoader:boolean= false;

  show() {
   this.isLoader=true;
  }
  hide() {
    this.isLoader=false;
  }

  addLoaderOnButton(bntRef){
    bntRef.disabled = true;
    bntRef.insertAdjacentHTML('beforeend', `<div  class="text-center mb-3">
    <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>`)
   // b?._elementRef?.nativeElement?.insertAdjacentHTML('beforeend', '<app-spinner class="btn-spinner position-absolute ms-1"></app-spinner>')
  }
  removeLoaderOnButton(b){
     b.disabled = false;
     b.removeChild(b.lastChild)
    // b?._elementRef?.nativeElement?.removeChild(b._elementRef.nativeElement.lastChild)  
  }
}