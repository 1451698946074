export const environment = {
  production: true,
  apiURL: "https://localhost:7035/",
  fileUrl: "https://compensatecommunity.com/files/",

  firebaseConfig: {
    apiKey: "AIzaSyDdlYIB9lfFDexQYCPgFrp6CiJ5BwyC_90",
    authDomain: "companset.firebaseapp.com",
    projectId: "companset",
    storageBucket: "companset.appspot.com",
    messagingSenderId: "1040918339181",
    appId: "1:1040918339181:web:d0affd6fdd32b0833d6964",
    measurementId: "G-BPZDCQKC0D",
    vapidKey: "BPQcrJ-GsUfwvgUIb7YWmkwE-LR0dWEPPcn73NZl_u1Se0o6Cd203C88sEBDYKJVOq2JW4z8wKzYBQZkGefWnr4",
  },
};
