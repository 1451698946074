import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, throwError } from "rxjs";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem("VictumAPanelToken");


        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            })
        }

        return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) { //&& !req.url.includes('auth/signin')
                return this.handle401Error(req, next);
            }
            return throwError(error);
        }));

    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        localStorage.clear();
        location.reload();
        return throwError("");
    }
}
