import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FullScreenLoaderComponent } from "./full-screen-loader/full-screen-loader.component";
import { ErrorPopupComponent } from "../app/shared/Components/error-popup/error-popup.component";

@NgModule({
  declarations: [
    FullScreenLoaderComponent,
    ErrorPopupComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FullScreenLoaderComponent
  ],
})
export class  SharedModule {}
